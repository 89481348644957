module.exports = {
  defaultTitle: "SayLab",
  logo: "https://sayakrasi.com/favicon/favicon-512.png",
  author: "Samuel Akrasi",
  url: "https://sayakrasi.com",
  legalName: "Samuel Akrasi",
  defaultDescription:
    "I’m Sam and I enjoy using information technology to solve problems!",
  socialLinks: {
    twitter: "https://www.twitter.com/",
    github: "https://github.com/codedlab/",
    linkedin: "https://www.linkedin.com/in/samuel-asamoa-yaw-a-60675351",
    instagram: "https://instagram.com/",
    youtube: "https://www.youtube.com/user/",
    google: "https://plus.google.com/u/0/",
  },
  googleAnalyticsID: "UA-88875900-4",
  themeColor: "#6b63ff",
  backgroundColor: "#6b63ff",
  social: {
    facebook: "facebook",
    twitter: "twitter",
  },
  address: {
    city: "Accra",
    region: "Greater Accra",
    country: "Ghana",
    zipCode: "23321",
  },
  contact: {
    email: "info@sayakrasi.com",
    phone: "0000000000",
  },
  foundingDate: "2020",
};
